<template lang="pug">
  div
    Spinner
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Spinner from 'vue-simple-spinner'
export default {
  name: 'Logout',
  components: {
    Spinner
  },
  data(){
    return {
      email:null,
      password:null,
    }
  },
  async created(){
    // alert('hi')
    var response = await this.axios.get('/api/logout')
    this.$store.dispatch('loginCheck')
    this.$router.go(-1)
    // this.$router.push({path:'/'})
    // alert('done')
    // console.log(response)    
  }
}
</script>
